var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"margin-bottom":"1em"}},[_c('v-toolbar',{attrs:{"color":"primary darken-4","dark":""}},[(_vm.editName)?_c('v-text-field',{ref:"editLabelField",attrs:{"solo":"","hide-details":"","light":""},on:{"blur":function($event){return _vm.finishEdit()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.finishEdit()}},model:{value:(_vm.labelEdit),callback:function ($$v) {_vm.labelEdit=$$v},expression:"labelEdit"}}):_c('v-toolbar-title',[_vm._v(_vm._s(_vm.label))]),_c('v-spacer'),(_vm.isCardEditable)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,false,675259685)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.editName = true}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-pencil")])],1),_c('v-list-item-title',[_vm._v("Change the group name")])],1),_c('v-list-item',{on:{"click":_vm.removeGroup}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-trash-alt")])],1),_c('v-list-item-title',[_vm._v("Remove this group")])],1)],1)],1):_vm._e()],1),_c('v-list',[_c('draggable',{on:{"end":_vm.updateFiles},model:{value:(_vm.myFiles),callback:function ($$v) {_vm.myFiles=$$v},expression:"myFiles"}},_vm._l((_vm.myFiles),function(ref,fileIndex){
var name = ref.name;
var mimeType = ref.mimeType;
var path = ref.path;
var editName = ref.editName;
return _c('v-list-item',{key:'file-' + fileIndex},[_c('v-list-item-avatar',{on:{"click":function($event){return _vm.open(name, path)}}},[(path && (path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://'))?_c('v-icon',[_vm._v("fal fa-link")]):(mimeType === 'application/pdf')?_c('v-icon',[_vm._v("fal fa-file-pdf")]):(mimeType === 'application/zip')?_c('v-icon',[_vm._v("fal fa-file-archive")]):(mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')?_c('v-icon',[_vm._v("fal fa-file-spreadsheet")]):_vm._e()],1),(editName)?_c('v-list-item-content',[_c('v-text-field',{attrs:{"outlined":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.updateFiles();_vm.myFiles[fileIndex].editName = false}},model:{value:(_vm.myFiles[fileIndex].name),callback:function ($$v) {_vm.$set(_vm.myFiles[fileIndex], "name", $$v)},expression:"myFiles[fileIndex].name"}})],1):_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-action',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.myFiles[fileIndex].editName = true}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("fal fa-pencil")])],1),_c('v-list-item-title',[_vm._v("Change "+_vm._s(path && (path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://') ? 'Link Text' : 'Filename'))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.removeFile(fileIndex)}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")])],1),_c('v-list-item-title',[_vm._v("Remove "+_vm._s(path && (path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://') ? 'Link' : 'File'))])],1)],1)],1)],1)],1)}),1)],1),_c('v-card-actions',[(_vm.isUploading)?_c('v-btn',{attrs:{"disabled":"","text":""}},[_vm._v("Uploading File...")]):(_vm.addFile)?_c('v-file-input',{attrs:{"label":"Add File","hint":"Click to search for a file or drop it on the box. The file will be added to the Google Drive folder linked below.","outlined":"","persistent-hint":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-7px"},attrs:{"icon":""},on:{"click":function($event){_vm.addFile = false}}},on),[_c('v-icon',[_vm._v("fal fa-times-circle")])],1)]}}])},[_c('span',[_vm._v("Cancel")])])]},proxy:true}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}):(_vm.addLink)?_c('v-text-field',{attrs:{"label":"Add Link","hint":"Enter the link including the opening http. Press enter when done to add the link.","outlined":"","persistent-hint":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-7px"},attrs:{"icon":""},on:{"click":function($event){_vm.addLink = false}}},on),[_c('v-icon',[_vm._v("fal fa-times-circle")])],1)]}}])},[_c('span',[_vm._v("Cancel")])])]},proxy:true}]),model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}):[_c('v-btn',{attrs:{"color":"success","disabled":_vm.folderId === '',"outlined":""},on:{"click":function($event){_vm.addFile = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-file")]),_vm._v(" Add File ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","outlined":""},on:{"click":function($event){_vm.addLink = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-link")]),_vm._v(" Add Link ")],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }