<template>
  <v-card style="margin-bottom:1em">
    <v-toolbar color="primary darken-4" dark>
      <v-text-field v-if="editName" ref="editLabelField" v-model="labelEdit" solo hide-details light @blur="finishEdit()" @keydown.enter="finishEdit()"></v-text-field>
      <v-toolbar-title v-else>{{ label }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="isCardEditable">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="editName = true">
            <v-list-item-avatar>
              <v-icon>fal fa-pencil</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Change the group name</v-list-item-title>
          </v-list-item>
          <v-list-item @click="removeGroup">
            <v-list-item-avatar>
              <v-icon color="error">fal fa-trash-alt</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Remove this group</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-list>
      <draggable v-model="myFiles" @end="updateFiles">
        <v-list-item v-for="({ name, mimeType, path, editName }, fileIndex) in myFiles" :key="'file-' + fileIndex">
          <v-list-item-avatar @click="open(name, path)">
            <v-icon v-if="path && (path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://')">fal fa-link</v-icon>
            <v-icon v-else-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
            <v-icon v-else-if="mimeType === 'application/zip'">fal fa-file-archive</v-icon>
            <v-icon v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-spreadsheet</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="editName">
            <v-text-field v-model="myFiles[fileIndex].name" outlined hide-details @keydown.enter="updateFiles();myFiles[fileIndex].editName = false"></v-text-field>
          </v-list-item-content>
          <v-list-item-title v-else>{{ name }}</v-list-item-title>
          <v-list-item-action>
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon>fal fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="myFiles[fileIndex].editName = true">
                  <v-list-item-avatar>
                    <v-icon color="info">fal fa-pencil</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>Change {{ path && (path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://') ? 'Link Text' : 'Filename' }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="removeFile(fileIndex)">
                  <v-list-item-avatar>
                    <v-icon color="error">fal fa-times-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>Remove {{ path && (path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://') ? 'Link' : 'File' }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </draggable>
    </v-list>
    <v-card-actions>
      <v-btn v-if="isUploading" disabled text>Uploading File...</v-btn>
      <v-file-input v-else-if="addFile" v-model="file" label="Add File" hint="Click to search for a file or drop it on the box. The file will be added to the Google Drive folder linked below." outlined persistent-hint>
        <template v-slot:append-outer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="addFile = false" style="margin-top:-7px">
                <v-icon>fal fa-times-circle</v-icon>
              </v-btn>
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </template>
      </v-file-input>
      <v-text-field v-else-if="addLink" v-model="link" label="Add Link" hint="Enter the link including the opening http. Press enter when done to add the link." outlined persistent-hint>
        <template v-slot:append-outer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="addLink = false" style="margin-top:-7px">
                <v-icon>fal fa-times-circle</v-icon>
              </v-btn>
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </template>
      </v-text-field>
      <template v-else>
        <v-btn color="success" :disabled="folderId === ''" outlined @click="addFile = true">
          <v-icon left>fal fa-file</v-icon>
          Add File
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" outlined @click="addLink = true">
          <v-icon left>fal fa-link</v-icon>
          Add Link
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script>
import axios from 'axios'
import { ref, watch } from '@vue/composition-api'
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    isCardEditable: {
      type: Boolean,
      default: true
    },
    folderId: {
      type: String,
      default: ''
    }
  },
  components: {
    draggable: () => import('vuedraggable')
  },
  setup (props, { root, emit }) {
    const editName = ref(false)
    watch(editName, (val) => {
      if (val === true) {
        root.$nextTick(() => {
          editLabelField.value.focus()
        })
      }
    })
    const editLabelField = ref(null)
    const labelEdit = ref(props.label)
    watch(() => props.label, () => {
      if (props.label !== labelEdit.value) labelEdit.value = props.label
    })
    function finishEdit () {
      emit('update', { label: labelEdit.value })
      editName.value = false
    }
    function removeGroup () {
      if (confirm('Are you sure you want to remove this file group? This cannot be undone.')) {
        emit('remove')
      }
    }

    const myFiles = ref(props.files)
    watch(() => props.files, () => {
      const arr = []
      for (let i = 0; i < props.files.length; i++) {
        arr.push({ ...props.files[i], editName: false })
      }
      myFiles.value = arr
    })
    function updateFiles () {
      emit('update', { files: myFiles.value })
    }

    function open (name, path) {
      if (path.substr(0, 4) === 'http') window.open(path)
      else {
        let token = window.localStorage['feathers-jwt']
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        axios({
          method: 'get',
          url: basePath + '/board/drive-file/' + path,
          responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
        }).catch((e) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error submitting: ' + e }
          root.$store.dispatch('main/snackbar', snackbar)
        }).then((response) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          if (name.substr(-4, 1) !== '.' && name.substr(-5, 1) !== '.') {
            switch (response.headers['content-type']) {
              case 'application/pdf':
                link.download += '.pdf'
                break
              case 'txt':
                link.download += '.txt'
            }
          }
          link.click()
        })
      }
    }

    const isUploading = ref(false)
    const file = ref(null)
    const link = ref('')
    const addFile = ref(false)
    const addLink = ref(false)
    watch(file, () => {
      if (file.value != null) {
        isUploading.value = true
        // Upload the file to the Google Drive folder for this term
        let formData = new FormData()
        const { name, type: mimeType } = file.value
        formData.append('filename', name)
        formData.append('file', file.value)
        formData.append('contentType', mimeType)
        formData.append('folder', props.folderId)
        let token = window.localStorage['feathers-jwt']
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        axios.post(basePath + '/board/drive-file', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } }).catch((e) => {
          let snackbar = { active: true, color: 'error', timeout: 10000, text: 'Error uploading file: ' + e }
          root.$store.dispatch('main/snackbar', snackbar)
          isUploading.value = false
        }).then((res) => {
          console.log(res)
          // Once the file is uploaded and we have the ID, emit an update to the parent to add it to the board/files document
          const id = res.data.id
          emit('add', { id, name, mimeType })
          // Clear the file so another one can be added
          addFile.value = false
          isUploading.value = false
          file.value = null
        })
      }
    })
    watch(link, () => {
      if (link.value !== '') {
        emit('add', { id: link.value, name: link.value })
        addLink.value = false
      }
    })

    function removeFile (index) {
      if (confirm('Are you sure you want to remove this file from the list?')) {
        myFiles.value.splice(index, 1)
        updateFiles()
      }
    }

    return {
      editName,
      editLabelField,
      labelEdit,
      finishEdit,
      removeGroup,
      myFiles,
      updateFiles,
      open,
      isUploading,
      file,
      link,
      addFile,
      addLink,
      removeFile
    }
  }
}
</script>
